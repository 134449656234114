import { ObjectEntries, HostKey } from './index';
import { host as configHost, cdn as configCdn, ignoreErrors } from './config.json';

const { location } = window;
const apiKey = import.meta.env.EVERLYTIC_FARO_API_KEY;
const name = import.meta.env.EVERLYTIC_FARO_APP_NAME;
const version = import.meta.env.EVERLYTIC_FARO_APP_VERSION;
const domainValidationWarningTimeout = import.meta.env.EVERLYTIC_DOMAIN_VALIDATION_WARNING_TIMOUT;
const domainValidationWarningExpiry = import.meta.env.EVERLYTIC_DOMAIN_VALIDATION_WARNING_EXPIRY;
const amplitutudeApiKey = import.meta.env.EVERLYTIC_AMPLITUDE_API_KEY;
const hostKey = getHostKey();
const cdn = configCdn[hostKey];

export const config = {
    a: apiKey,
    n: name,
    v: version,
    domainValidationWarningTimeout,
    domainValidationWarningExpiry,
    cdn,
    ignoreErrors,
    amplitutudeApiKey
};

export const processImageUrlsConfig = {
    selector: {
        image: 'img'
    },
    regex: {
        spaces: /\s/g
    },
    replace: {
        htmlSpace: encodeURIComponent(' ')
    }
};


function getHostKey() {
    let hostKey: HostKey = 'prod';

    for (const [key, hosts] of ObjectEntries(configHost)) {
        if (hosts.includes(location.host)) {
            hostKey = key;

            break;
        }
    }

    return hostKey;
}