import { initializeFaro } from '@grafana/faro-web-sdk';
import { config  } from '../config';
import { host as hostConfig } from '../../lib/config/lib.config.json';
import { getDependencyVersionsLogging as getDependencyVersions } from '../util/dependency.util';
import { relayLogItem } from '../util/relay-log-item.util';

const { a: apiKey, n: name, v: defaultVersion, ignoreErrors = [] } = config;
const { dev = [], staging = [] } = hostConfig;
const { EV_CORE_META = {} } = window;
const { origin, host } = location;
const {  documentElement } = document;
const everlyticRelayLogging = Number([...dev, ...staging].includes(host) || documentElement?.dataset?.everlyticRelayLogging || 0);
const dependency = getDependencyVersions();
const {
    e = '',
    c = '',
    u = '',
    i = origin,
    v = defaultVersion
} = EV_CORE_META;
const user = {
    id: u,
    attributes: {
        e,
        c,
        u,
        i,
        v,
        ...dependency
    }
};
const url = `${origin}/__/journal`;
const app = {
    name,
    version: v
};
const regex = {
    ignoreError: new RegExp(ignoreErrors.join('|'))
};
const beforeSend = everlyticRelayLogging ? beforeSendRelayHandler : beforeSendHandler;
const initializeConfig = { url, apiKey, app, user, beforeSend };

initialize();

function initialize() {
    window.EV_LOGGER = initializeFaro(initializeConfig);
}

function beforeSendHandler(itemParams: any) {
    const item: IItem = itemParams;

    let currentItem: any;
    let ignore = false;
    
    switch(item.type) {
        case 'exception':
            ignore = Boolean(item?.payload?.value?.match?.(regex.ignoreError));

            if (!ignore) {
                currentItem = itemParams;
            }

            break;
        default:
            currentItem = itemParams;
            
            break;
    }

    return currentItem;
}

function beforeSendRelayHandler(item: any) {
    relayLogItem(item);

    return undefined;
}

interface IItem {
    type: 'exception' | string;
    payload: {
        value?: string;
    };
}