export function getDependencyVersions() {
    const { jQuery, angular, ace } = window;
    const dependency = {
        jQuery: jQuery?.()?.jquery || -1,
        jQueryUi: jQuery?.ui?.version || -1,
        bootstrap: jQuery?.fn?.tooltip?.Constructor?.VERSION || -1,
        angularJs: angular?.version?.full || -1,
        ace: ace?.version || -1
    };

    return dependency;
}

export function getDependencyVersionsLogging() {
    const dependency = getDependencyVersions();
    const dependencyLogging = {} as Record<string, string>;

    for (const [key, value] of Object.entries(dependency)) {
        dependencyLogging[`dependency_${key}`] = value.toString();
    }

    return dependencyLogging;
}

export function updateDependencyData() {
    setDependencyData();
    logDependencyData();
}

function setDependencyData() {
    const { document } = window;
    const { documentElement } = document;
    const { dataset } = documentElement;
    const dependency = getDependencyVersions();

    for (const [key, value] of Object.entries(dependency)) {
        dataset[key.toLowerCase()] = value.toString();
    }
}

function logDependencyData() {
    const { EV_LOGGER: logger } = window;
    const dependency = getDependencyVersionsLogging();
    const configAttributes = logger?.config?.user?.attributes || {};
    const attributes = {
        ...configAttributes,
        ...dependency
    };

    logger?.api?.setUser({ attributes });
}